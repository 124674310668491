import axios from 'axios';
import serialize from 'form-serialize';
import { playMedia } from '../../../../../assets/js/utils/playPauseMedia';

// Analytics
function trackMomentsDownload(label) {
  if ('dataLayer' in window && typeof window.dataLayer.push === 'function') {
    window.dataLayer.push({
      event: 'SendEvent',
      eventCategory: 'GG Download',
      eventAction: 'Download',
      eventLabel: label,
    });
  }
}

function requestEmail(location, data) {
  return axios.post(location, data, {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  });
}

function initMomentsHero(el) {
  if (!(el instanceof HTMLDivElement)) return;

  const video_desktop = el.querySelector('.moments-hero__desktop-video');
  const video_mobile = el.querySelector('.moments-hero__mobile-video');

  const words: HTMLElement[] = Array.from(el.querySelectorAll('.rotating-word'));
  const interval = parseInt(el.getAttribute('data-interval'), 10) || 1500;
  let wordIndex = 0;

  if (words.length >= 2) {
    function updateTextRotation() {
      words.forEach(word => word.classList.remove('active'));

      words[wordIndex % words.length].classList.add('active');

      wordIndex++;
    }

    setInterval(updateTextRotation, interval);
  }

  if (video_desktop instanceof HTMLVideoElement && video_mobile instanceof HTMLVideoElement) {
    bindAccessibilityPause(video_desktop);
    bindAccessibilityPause(video_mobile);
  }


  function bindAccessibilityPause(video: HTMLVideoElement) {
    video.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        e.preventDefault();
        if (video.paused) {
          playMedia(video);
        } else {
          video.pause();
        }
      }
    });
  }

  // Send Email Download button
  const sendEmailDownloadForm = el.querySelector('.moments-hero__form');
  if (sendEmailDownloadForm instanceof HTMLFormElement) {
    sendEmailDownloadForm.addEventListener('submit', (event) => {
      event.preventDefault();

      // Validate form
      const email = el.querySelector('.moments-hero__download-email');
      if (email instanceof HTMLInputElement) {
        if (email.value === '') {
          const parentDiv = el.querySelector('.moments-hero__email-link');
          if (parentDiv instanceof HTMLDivElement) {
            parentDiv.classList.add('moments-hero__validation_failed');
          }
          return false;
        }


        const data = serialize(sendEmailDownloadForm);
        const action = sendEmailDownloadForm.getAttribute('action');

        requestEmail(action, data).then(
          (response) => {
            const successMessage = el.querySelector('.moments-hero__success-message');
            if (successMessage instanceof HTMLDivElement) {
              successMessage.classList.remove(
                'moments-hero__hidden'
              );
            }
            const emailDownloadFormDiv = el.querySelector('.moments-hero__email-link');
            const emailDescription = el.querySelector('.moments-hero__email-description');
            const downloadDescription = el.querySelector('.moments-hero__download-description--mobile');

            if (emailDownloadFormDiv instanceof HTMLDivElement &&
              emailDescription instanceof HTMLDivElement &&
              downloadDescription instanceof HTMLDivElement) {
              emailDownloadFormDiv.classList.add('moments-hero__hidden');
              emailDescription.classList.add('moments-hero__hidden');
              downloadDescription.classList.add('moments-hero__hidden');
            }
          },
          (error) => {
            const errorMessages = [];
            if (error.response) {
              // We got a response from the server, just output all of them
              Object.keys(error.response.data).forEach(key => {
                const messages = error.response.data[key];
                messages.forEach(m => errorMessages.push(m));
              });
            } else {
              // Something else went wrong, use a generic message.
              errorMessages.push('Something went wrong, please try again later.');
            }
            console.log(errorMessages);
          },
        );
      }
    });
  }

  // Download button
  const downloadButton = el.querySelector('.moments-hero__download-button');
  if (downloadButton instanceof HTMLAnchorElement) {
    downloadButton.addEventListener('click', () => {
      trackMomentsDownload('Moments Hero');
      setTimeout(function () {
        window.location.href = '/gg/moments/download/';
      }, 2000);
    });
  }
}

const momentsHero = Array.from(document.querySelectorAll('.js-moments-hero'));
momentsHero.forEach(initMomentsHero);
